import React from 'react'
import { Link } from "gatsby"

export default function Layout({ children }) {
	return (
		<div>
			<header className="bg-gradient-to-r from-red-600 to-red-700 text-white py-4 px-4">
				<div className='container mx-auto flex flex-col gap-4 md:flex-row justify-between items-center'>
					<Link to="/" className='flex items-center gap-2'>
						<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
							<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
						</svg>
						<h1>Cinema HD</h1>
					</Link>
					<nav className='hidden md:block'>
						<ul className='flex flex-col md:flex-row gap-3 items-center font-semibold'>
							<li><Link to="/download/" className="hover:underline">Download</Link></li>
							<li><Link to="/cinema-hd-ios/" className="hover:underline">iOS (iPhone or iPad)</Link></li>
							<li><Link to="/cinema-hd-for-pc/" className="hover:underline">Windows PC</Link></li>
							<li><Link to="/cinema-hd-firestick/" className="hover:underline">Firestick or Smart TV</Link></li>
							<li><Link to="/cinema-hd-alternatives/" className="hover:underline">Alternatives</Link></li>
						
				
						
						</ul>
					</nav>
				</div>
			</header>
			<div className='bg-white container mx-auto px-6 py-8 min-h-screen max-w-7xl'>
				{ children }
			</div>
			<footer className='bg-gradient-to-r from-red-600 to-red-700 text-white px-4 py-8'>
				
				<section className='container mx-auto grid md:grid-cols-3 gap-6 mb-10'>
					<div>
						<h3 className='mb-4'>Socials</h3>
						<p className='mb-4'>Download Cinema HD v2 APK for Android.</p>
						<ul>
							<li><Link target="_blank" className='hover:underline' href="https://facebook.com/">Facebook</Link></li>
						</ul>
					</div>
					<div>
						<h3 className='mb-4'>Join our Community</h3>
					</div>
					<div>
						<h3 className='mb-4'>Disclaimer</h3>
						<p>We are not the developers of Cinema HD. This blog is only for informational purpose. Please contact us for any issue.</p>
					</div>
				</section>
				<nav className='flex justify-around mb-6'>
					<ul className='flex flex-row md:gap-8 gap-2 items-center font-semibold'>
						<li><Link className="hover:underline" to="/contact/">Contact</Link></li>
						<li><Link className="hover:underline" to="/privacy-policy/">Privacy Policy</Link></li>
					</ul>
				</nav>
				<p className='text-center font-semibold'>&copy; Cinema HD</p>
			</footer>
		</div>
	)
}